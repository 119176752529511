.signin-icon-wrapper {
    position: relative;
    box-sizing: border-box;

    width: 48px;
    height: 48px;

    background: linear-gradient(180deg, #FFFFFF 0%, #D0D5DD 100%), #FFFFFF;
    border: 0.3px solid #D0D5DD;
    border-radius: 12px;
}

.signin-icon-dot {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    border-radius: 50%;

    background: linear-gradient(26.57deg, #53389E 8.33%, #6941C6 91.67%);
    /* Shadow/sm */

    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}

.signin-icon-blur {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 50%;
    bottom: 0%;

    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3.75px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 0px 0px 12px 12px;
}

.signin-switch-text {
    color: #475467;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}