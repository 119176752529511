$secondary: #323232;
$primary-light: #F4EBFF;
$primary-lighter: #F9F5FF;

@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "bootswatch";

@import "~bootstrap-icons/font/bootstrap-icons.css";

$border-color: $gray-300;


@import "emojis";
@import "happyix";
@import "questions";
@import "builder";
@import "signin";
@import "components";

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  font-family: Inter, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#root {
  // min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.pointer {
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gap {
  gap: 1rem;
}

.flex-unset {
  flex: unset !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.bold {
  font-weight: bold;
}

.invisible {
  opacity: 0;
}


input.inline,
textarea.inline {
  background: inherit;
  border: 0;
  padding: 0;
  resize: none;

  &:disabled {
    background: inherit;
  }
}

.bg-inherit {
  background: inherit;
}

.nav-link.active {
  font-weight: bolder;
}

.no-wrap {
  white-space: nowrap;
}

.flex-direction-row {
  flex-direction: row;
}

.bg-primary-light {
  background-color: $primary-light;
}

.bg-danger-light {
  background-color: #FEE4E2;
}

.bg-danger-lighter {
  background-color: #FEF3F2;
}

p {
  color: #475467
}

.btn {
  // scss-docs-start btn-css-vars
  --bs-btn-disabled-border-color: transparent;
}

.btn-form-control {
  border: 1px solid #D9D9D9;
}

.shadow {
  box-shadow: 5px 5px 5px $border-color;
}

//////////
// SPINNER
//////////

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 100px;
  height: 100px;
  margin: 1rem;

  & .path {
    stroke: var(--bs-primary);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

.spinner.small {
  width: 20px;
  height: 20px;
  margin: 0;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}