.question {

    h1,
    .header {
        text-align: center;
        margin-bottom: 2rem;
    }


    .answer {
        height: 100px;
        border-radius: 50px;
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
    }

    form {
        margin: 0 2rem;
    }

    textarea {
        margin-bottom: 2.5rem;
        font-size: 2rem;
    }

    button {
        font-size: 2rem;
        padding: 1.5rem 2.5rem;
    }

    .btn-answer {
        font-family: var(--bs-btn-font-family);
        color: $secondary;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        user-select: none;
        border: 1px solid $secondary;
        background-color: var(--bs-btn-bg);
        font-weight: 500;
    }

    &.small {
        padding-top: 0.2rem;

        h1 {
            font-size: 0.5rem;
            margin-bottom: 0.2rem;
        }

        .answer {
            height: 20px;
            border-radius: 10px;
            padding-left: 20px;
            margin-bottom: 5px;
            font-size: 0.5rem;
        }

        form {
            margin: 0 0.2rem;
        }

        textarea {
            margin-bottom: 0.2rem;
            font-size: 0.5rem;
        }

        button {
            font-size: 1rem;
            padding: 0.1rem 0.2rem;
        }

    }
}

@media (max-width: 500px) {
    .question {
        .answer {
            height: 50px;
            border-radius: 25px;
            margin-bottom: 1rem;
            font-size: 1rem;
        }

        button {
            font-size: 1rem;
            padding: 1rem 1.5rem;
        }
    }
}