.btn-ternary {
    color: $primary;
    background-color: $primary-lighter;

    &:hover,
    &:focus,
    &:active,
    &:first-child:active {
        color: $primary-lighter;
        background-color: $primary;
    }
}

.btn-outline-secondary {
    border-color: #D0D5DD;
}

$builder_button_size: 45px;

.btn-builder {
    min-width: $builder_button_size;
    min-height: $builder_button_size;
}