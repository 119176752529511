$base_size: 210px;
$small_size: 30px;

.smiley {
    width: $base_size;
    height: $base_size;
}

.smiley.small {
    width: $small_size;
    height: $small_size;
}

@media (max-width: 1400px) {
    $big_tablet_size: 150px;

    .smiley {
        width: $big_tablet_size;
        height: $big_tablet_size;
    }
}

@media (max-width: 900px) {
    $tablet_size: 100px;

    .smiley {
        width: $tablet_size;
        height: $tablet_size;
    }
}

@media (max-width: 500px) {
    $phone_size: 64px;

    .smiley {
        width: $phone_size;
        height: $phone_size;
    }
}