.happy-ix {
    border-radius: 50%;

    .mask {
        z-index: 20;
        position: absolute;
        border-radius: 50%;
    }

    .backdrop {
        z-index: 10;
        position: absolute;
        border-radius: 50%;
        background-color: var(--bs-light);
    }


    .inner {
        position: absolute;

        z-index: 30;
        background: var(--bs-white);
        border-radius: 50%;
    }
}