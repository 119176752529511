$builder-width: 400px;

.builder-tools-above-list {
    min-width: $builder-width;
}

.builder-list {
    display: flex;
    flex-direction: column;
    width: $builder-width;
    background: $gray-400;
}

.step-icon {
    height: 200px;
    background: var(--bs-white);
    overflow: hidden;
}

.builder-list-item {
    transition: background 0.5s;
}

.builder-list-item.selected {
    background: var(--bs-primary);
}

.radius-full {
    border-radius: 9000px;
}